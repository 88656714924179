export default {
  ml: 'мл',
  cup: 'склянка',
  price: 'ціна',
  payByCard: 'оплатити карткою',
  back: 'назад',
  scanQrCoffeeMachine: 'Будь ласка, проскануйте QR-код на кавоварці!',
  coffeeMachineOffline: 'Кавоварка не в мережі, спробуйте пізніше.',
  errorNextTry: 'Помилка мережі, ми намагаємось виправити з`єднання, зачекайте.',
  fatalError: 'Невиправна помилка мережі, повторіть спробу пізніше.',
  getCard: 'прикладіть картку до платіжного терміналу',
  paymentSuccess: 'оплата пройшла успішно',
  paymentError: 'помилка платежу, спробуйте ще раз',
  gettingCup: 'видаємо склянку, зачекайте',
  preparing: 'приготування',
  takeYourDrink: 'готово, візьміть свій напій',
  beverageNotTakad: 'Візьміть свій напій, інакше він буде утилізований!',
  beverageDisposal: 'Утилізація напою',
  busy: 'Кавоварка зайнята, зачекайте завершення приготування напою.',
  noCupAvailable: 'Обраний розмір склянок закінчився, будь ласка, виберіть інший напій',
  cupIssueBlocked: 'Звільніть зону видачі склянок',
  error: 'Ой... помилка, повторіть спробу пізніше',
  beverageError: 'Кавоварка не готова, повторіть спробу пізніше.',
  moneyRefund:
    'Сталася помилка, проводимо повернення коштів на картку, приносимо вибачення за незручності',
  takeCup: 'Будь ласка, візьміть склянку та поставте в кавоварку.',
  cupNotTaked: 'Будь ласка, візьміть склянку та поставте в кавоварку.',
  cupNotTakedStartBeverage:
    'Будь ласка, візьміть склянку та поставте в кавоварку. Приготування напою розпочнеться через 5 секунд.',
}
