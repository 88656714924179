export default {
  ml: 'ml',
  cup: 'cup',
  price: 'price',
  payByCard: 'pay by card',
  back: 'back',
  scanQrCoffeeMachine: 'Please scan QR on the coffee machine!',
  coffeeMachineOffline: 'The coffee machine is offline, try later.',
  errorNextTry: 'Network error, we are trying to fix the connection, please wait.',
  fatalError: 'Fatal network error, please try again later.',
  getCard: 'bring the card to the payment terminal',
  paymentSuccess: 'payment successful',
  paymentError: 'payment error, please try again',
  gettingCup: 'give out a cup, please wait',
  preparing: 'preparing',
  takeYourDrink: 'is ready, take your drink',
  beverageNotTakad: 'Take your drink or it will be disposed of!',
  beverageDisposal: 'Beverage disposal',
  busy: 'The coffee machine is busy, please wait until the coffee is ready.',
  noCupAvailable: 'Selected cup size is over, please choose another drink',
  cupIssueBlocked: 'Clear the cup dispensing area',
  error: 'Ups... error, try again later',
  beverageError: 'The coffee machine is not ready, please try again later.',
  moneyRefund: 'An error has occurred, we are making a refund to the card, we apologize for the inconvenience',
  takeCup: 'Please take the cup and place it in the coffee machine.',
  cupNotTaked: 'Please take the cup and place it in the coffee machine.',
  cupNotTakedStartBeverage: 'Beverage brewing will start after 5 sec.',
}
