/* eslint no-shadow: ["error", { "allow": ["state"] }] */

// import { findIndex, keyBy, reject } from 'lodash';
// import { map } from 'lodash'
import Vue from 'vue'

import { FETCH_SHOP } from '../../actions.type'
import {
  SET_SHOP_REQUEST,
  SET_SHOP_VALUE,
} from '../../mutations.type'

const state = {
  shop: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined,
    },
    value: {},
  },
}

const getters = {
  shopValue: (state) => state.shop.value,
  shopRequest: (state) => state.shop.request,
  shopBeverageGroups: (state) => state.shop.value.beverageGroups,
}

const actions = {
  async [FETCH_SHOP]({ commit, state }, id) {
    if (
      state.shop.request.isLoading ||
      state.shop.request.isError
    ) {
      return
    }
    try {
      const { data } = await Vue.axios.get(`shop/${id}`, {
        'axios-retry': {
          beforeTry: () => {
            commit(SET_SHOP_REQUEST, {
              isLoading: true,
              isError: false,
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_SHOP_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime,
            })
          },
        },
      })
      commit(SET_SHOP_REQUEST, {
        isSuccess: true,
      })
      commit(SET_SHOP_VALUE, data)
    } catch (e) {
      commit(SET_SHOP_REQUEST, {
        isFatalError: true,
      })
    }
  },
}

const mutations = {
  [SET_SHOP_REQUEST](state, request) {
    state.shop.request = {
      ...request,
    }
  },
  [SET_SHOP_VALUE](state, brands) {
    state.shop.value = brands
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
